<script src="@/api/index.js"></script>
<template>
	<div class="container">
		<div class="titleimg">
			<img src="@/assets/img/login_title.png">
		</div>
		<div class="container_box">
			<div class="container_title">历&nbsp;史&nbsp;答&nbsp;题&nbsp;记&nbsp;录</div>
			<van-row class="container_box_title" type="flex" justify="space-around">
				<van-col>日期</van-col>
				<van-col>答题分数</van-col>
				<van-col>用时</van-col>
				<van-col>操作</van-col>
			</van-row>
			<template v-for="(item, index) in examList">
				<van-row class="container_box_ph" type="flex" justify="space-around">
					<van-col class="container_box_ph_item po_r">
						<div>{{ item.begin_date }}</div>
					</van-col>
					<van-col class="container_box_ph_item">
						{{ item.points }}分
					</van-col>
					<van-col class="container_box_ph_item">
						{{ item.total_date }}
					</van-col>
					<van-col class="container_box_ph_item" @click="handleClick(item)">
						查看
					</van-col>
				</van-row>
			</template>
		</div>
		<img src="../../assets/img/zbfw.png" style="width: 75%;display: block;padding: 25px 0px;margin: auto;" alt="">
	</div>
</template>

<script>
	import {
		getExam
	} from '@/api'
	export default {
		name: "hosdati",
		data() {
			return {
				examList:[],
			}
		},
		watch: {

		},
		created() {
			this.get_Exam()
		},
		methods: {
			handleClick(item) {
				this.$router.push({
					name: 'cj',
					query: {
						item
					}
				})
			},
			get_Exam() {
				getExam().then(res => {
					if(res.data.code==1){
						this.examList=res.data.data
					}else{
						this.$toast.fail(res.data.msg)
					}
					
				})
			},
		}
	}
</script>
<style scoped lang="scss">
	.container {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		padding-bottom: 50px;
		background: url(../../assets/img/beijing.png) no-repeat center;
		background-size: 100% 100%;
		position: relative;
		&_title{
			font-weight: bold;
			font-size: 58px;
			text-align: center;
			color: #1E8A94;
		}
		.titleimg {
			text-align: center;
			position: relative;
			z-index: 2;
		
			img {
				width: 75%;
				margin-top: 80px;
			}
		}
		&_box {
			background-color: rgba(255, 255, 255, 0.8);
			border-radius: 50px;
			padding: 33px 30px 33px 30px;
			height: 900px;
			overflow-y: scroll;
			width: 600px;
			margin: 50px auto 0px;
			position: relative;
			.active {
				color: #fd8227 !important;
			}

			&_title {
				font-size: 28px;
				color: white;
				display: flex;
				justify-content: space-around;
				background-color: #FEA028;
				align-items: center;
				height: 60px;
				margin: 20px auto;
			}

			&_ph {
				width: 100%;
				font-size: 28px;
				color: #333333;
				display: flex;
				justify-content: space-around;
				align-items: center;
				margin-top: 30px;

				.po_r {
					position: relative;
				}

				&_item {
					width: 20%;
					text-align: center;
					font-size: 24px;
					color: #1E8A94;
					img {
						width: 72px;
						height: 72px;
					}

					.po_a {
						position: absolute;
						top: 34px;
						left: 58px;
					}
				}

				&_item2 {
					width: 33.33%;
					text-align: center;

					img {
						width: 72px;
						height: 72px;
					}

					.po_a {
						position: absolute;
						top: 30px;
						left: 72px;
					}
				}

				/* &_item:nth-child(2) {
		  width: 10%;
		  text-align: center;
		}*/
				.item_flex {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				&_img {
					width: 68px;
					height: 68px;
					background-color: deeppink;
					border-radius: 50%;
					margin-right: 10px;
				}
			}
		}
	}
</style>